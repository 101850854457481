import React, { useState } from "react";
import io from "socket.io-client";
import { CSSTransition } from "react-transition-group";
import "../App.css";

const App = () => {
  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(-1);
  // Add a new state variable for the thumbnails
  const [thumbnails, setThumbnails] = useState([]);

  const socket = io();

  // Listen for 'new image' messages from the server
  socket.on("new image", (imageData) => {
    console.log("Received new image data:", imageData);

    // Add the new image to the state array of images
    setImages([...images, imageData]);
    setCurrentImageIndex(0);

    // Generate a thumbnail for the new image
    const reader = new FileReader();
    reader.readAsDataURL(imageData);
    reader.onloadend = () => {
      setThumbnails([...thumbnails, reader.result]);
    };
  });

  const handleImageUpload = (event) => {
    // Get the files from the input field
    const files = event.target.files;

    // Add the files to the state array of images
    setImages([...images, ...files]);
    setCurrentImageIndex(0);

    // Generate thumbnails from the images
    const newThumbnails = [];
    for (const file of files) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        newThumbnails.push(reader.result);
        setThumbnails([...thumbnails, ...newThumbnails]);
      };
    }

    // Send the image data to the server over the websocket
    socket.emit("new image", files[0]);
  };

  const handleClearAll = () => {
    setImages([]);
    setThumbnails([]); // Clear the thumbnails array
    setCurrentImageIndex(-1);
  };

  const handleClear = () => {
    setImages(images.slice(0, 2)); // Keep the first two images
    setThumbnails(thumbnails.slice(0, 2)); // Keep the first two thumbnails
    setCurrentImageIndex(-1);
  };

  const handleNext = () => {
    if (images.length > 0) {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    }
  };

  const handlePrevious = () => {
    setCurrentImageIndex(
      (currentImageIndex - 1 + images.length) % images.length
    );
  };

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("thumbnailIndex", index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    // Reorder the thumbnails
    const thumbnailIndex = event.dataTransfer.getData("thumbnailIndex");
    const newThumbnails = [...thumbnails];
    const droppedThumbnail = newThumbnails.splice(thumbnailIndex, 1)[0];
    const dropIndex = event.target.getAttribute("data-thumbnail-index");
    newThumbnails.splice(dropIndex, 0, droppedThumbnail);
    setThumbnails(newThumbnails);

    // Reorder the images to match the new thumbnail order
    const newImages = [...images];
    const droppedImage = newImages.splice(thumbnailIndex, 1)[0];
    newImages.splice(dropIndex, 0, droppedImage);
    setImages(newImages);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
      {/* Column of thumbnails */}
      <div
        style={{
          width: "100px",
          display: "flex",
          flexDirection: "column",
          marginLeft: "200px",
        }}
      >
        {thumbnails.map((thumbnail, index) => (
          <img
            key={index}
            src={thumbnail}
            alt="thumbnail"
            style={{ width: "100%", cursor: "pointer", marginBottom: "20px" }}
            onClick={() => setCurrentImageIndex(index)}
            draggable
            onDragStart={(event) => handleDragStart(event, index)}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            data-thumbnail-index={index}
          />
        ))}
      </div>

      {/* Image container */}
      <div style={{ width: "1105px" }}>
        <h1 style={{ color: "#33F5FF" }}>GeekPodVerse images</h1>
        <input
          type="file"
          multiple
          onChange={handleImageUpload}
          className="button"
        />
        <button onClick={handleClear} className="button">
          Clear Images
        </button>
        <button onClick={handleClearAll} className="button">
          Clear All Images
        </button>
        <button onClick={handlePrevious} className="button">
          Previous
        </button>
        <button onClick={handleNext} className="button">
          Next
        </button>
        <div style={{ marginTop: "100px" }}>
          <div
            className="image-column"
            style={{
              border: "solid 3px red",
              width: "1105px",
              height: "663px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {images.length > 0 && currentImageIndex !== -1 ? (
              <CSSTransition
                in={true}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <img
                  src={URL.createObjectURL(images[currentImageIndex])}
                  alt="uploaded"
                  style={{
                    width: "1105px", // Set the width to 1105px
                    height: "663px", // Set the height to 663px
                    objectFit: "contain", // Scale the image to fit within the given dimensions while maintaining the aspect ratio
                  }}
                />
              </CSSTransition>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
